<template>
  <q-page class="q-pa-sm">
    <div class="row">
      <div class="col-12">
        <q-item>
          <q-input v-model="entity.state.feedback.editItem.user_name" class="full-width" clearable dense
                   label="Имя пользователя" outlined/>
        </q-item>
      </div>
      <div class="col-12">
        <q-item>
          <q-input v-model="entity.state.feedback.editItem.comment" class="full-width" clearable dense
                   label="Комментарий"
                   hint="Максимальная длина - 300 символов"
                   outlined type="textarea" :maxlength="300"/>
        </q-item>
      </div>
      <div class="col-12">
        <q-item>
          <q-input v-model="entity.state.feedback.editItem.rating" class="full-width" dense label="Рейтинг" max="5"
                   min="1" outlined
                   type="number">
          </q-input>
        </q-item>
      </div>
      <div class="col-12">
        <q-item><b class="date_title">Дата отзыва: {{ $filters.toHumanDate(entity.state.feedback.editItem.date) }}</b>
          <q-btn class="q-ml-lg" icon="edit" round size="sm">
            <q-popup-proxy cover transition-hide="scale" transition-show="scale">
              <q-date v-model="entity.state.feedback.editItem.date" mask="YYYY-MM-DD">
                <div class="row items-center justify-end q-gutter-sm">
                  <q-btn v-close-popup color="primary" flat label="Очистить"
                         @click="entity.state.feedback.editItem.date = null;"/>
                </div>
              </q-date>
            </q-popup-proxy>
          </q-btn>
        </q-item>
        <q-item>
          <!--          <q-input v-model="entity.state.feedback.editItem.date" class="full-width" dense-->
          <!--                   label="Дата создания"-->
          <!--                   type="date">-->
          <!--          </q-input>-->
          <!--          <q-date v-model="entity.state.feedback.editItem.date" title="Дата отзыва" mask="YYYY-MM-DD">-->
          <!--            <div class="row items-center justify-end q-gutter-sm">-->
          <!--              <q-btn v-close-popup color="primary" flat label="Отменить"/>-->
          <!--              <q-btn v-close-popup color="primary" flat label="OK"/>-->
          <!--            </div>-->
          <!--          </q-date>-->

        </q-item>
      </div>
      <div class="col-12">
        <q-item>
          <image-uploader v-if="entityId" v-model="entity.state.feedback.editItem.icon_url"
                          :uploadMethod="file => saveImage(file)"
                          class="full-width"
                          label="Изображение для отзыва (PNG/JPEG)"/>
        </q-item>
      </div>
    </div>
    <div class="q-pa-md">

      <q-btn class="q-mr-md" color="primary" icon="save" label="Сохранить" rounded @click="saveFeedbackEntity"/>

      <q-btn class="q-mr-md" icon="cancel" label="Назад" rounded @click="backPage"/>
    </div>
  </q-page>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { date, Notify } from 'quasar';
import { useStore } from 'vuex';
import { FeedbackApi } from '@/api/apis/feedback-api.ts';
import ImageUploader from '@/components/forms/imageUploader';
import store from '@/store';
import $filters from '../../helpers/filters';

export default defineComponent({
  computed: {
    $filters() {
      return $filters;
    },
    // date() {
    //   return date
    // }
  },

  components: {
    ImageUploader,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    onMounted(getFeedbackById);

    const entityId = ref(route.params.id);
    const entity = useStore();
    const loading = ref(false);

    async function getFeedbackById() {
      if (entityId.value) {
        const response = await new FeedbackApi().feedbackGetItems();
        await store.dispatch('updateFeedbacks', response.data);
        const [currentFeedback] = store.state.feedback.data.filter((el) => (el.id === Number.parseInt(entityId.value)));
        entity.dispatch('editFeedbackItemUpdate', currentFeedback);
      }
    }

    async function saveFeedbackEntity() {
      loading.value = true;
      const feedback = {
        comment: entity.state.feedback.editItem.comment,
        rating: Number(entity.state.feedback.editItem.rating),
        user_name: entity.state.feedback.editItem.user_name,
        date: entity.state.feedback.editItem.date,
      };
      try {
        if (entityId.value) {
          const { data } = await new FeedbackApi().feedbackUpdate(feedback, entityId.value);
        } else {
          const { data } = await new FeedbackApi().feedbackCreate(feedback);
          entityId.value = data.id;
        }

        Notify.create({
          type: 'positive',
          message: 'Сохранено',
        });
      } catch (e) {
        console.error(e);
      }
      loading.value = false;
    }

    async function saveImage(file) {
      try {
        loading.value = true;
        const response = await new FeedbackApi().feedbackUploadImage(file, entityId.value || 1);
        entity.dispatch('editFeedbackItemUpdate', {
          comment: entity.state.feedback.editItem.comment,
          rating: Number(entity.state.feedback.editItem.rating),
          user_name: entity.state.feedback.editItem.user_name,
          date: entity.state.feedback.editItem.date,
          icon_url: response.data?.url,
        });
        if (response.status === 201) {
          Notify.create({
            type: 'positive',
            message: 'Иконка сохранена',
          });
        }
        return response;
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    }

    const backPage = () => {
      router.back();
      entity.dispatch('editFeedbackItemUpdate', null);
    };
    return {
      entity,
      FeedbackApi,
      loading,
      backPage,
      saveFeedbackEntity,
      entityId,
      saveImage,
    };
  },
});
</script>
<style>
</style>
